const antiAliasing = {
  WebkitFontSmoothing: 'antialiased',
  MozOsxFontSmoothing: 'grayscale',
};

const font = {
  primary: {
    light: {
      fontFamily: 'Proxima Nova',
      fontWeight: 400,
      ...antiAliasing,
    },
    bold: {
      fontFamily: 'Proxima Nova',
      fontWeight: 700,
      ...antiAliasing,
    },
  },
  secondary: {
    light: {
      fontFamily: 'Majesti Banner',
      fontWeight: 400,
      ...antiAliasing,
    },
    bold: {
      fontFamily: 'Majesti Banner',
      fontWeight: 700,
      ...antiAliasing,
    },
  },
};

export const mixins = {
  font,
};
