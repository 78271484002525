import React from 'react';
import {ButtonProps} from 'antd';
import {StyledButton, StyledInfoButton, StyledLink, StyledButtonMore, IconMore} from './styles';

export const Button: React.FC<ButtonProps & InfoButtonProps> = ({...props}) => <StyledButton {...props} />;

export enum ButtonTypes {
  primary = 'primary',
  secondary = 'secondary',
  tertiary = 'tertiary',
  quaternary = 'quaternary',
  quinary = 'quinary',
  senary = 'senary',
}
export type InfoButtonProps = {
  variant?: ButtonTypes;
};

export const InfoButton: React.FC<ButtonProps & InfoButtonProps> = ({...props}) => <StyledInfoButton {...props} />;

export const LinkButton: React.FC<ButtonProps & InfoButtonProps & {to: string}> = ({to, ...props}) => (
  <StyledLink {...props} to={to}>
    <StyledButton {...props} />
  </StyledLink>
);

export const ButtonMore: React.FC<ButtonProps> = ({...props}) => (
  <StyledButtonMore {...props} type={'text'} icon={<IconMore />} />
);
