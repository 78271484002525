import React from 'react';
import {ForgotPassword} from '../components/Forgot';
import {useForgotPassword, useResetWithCode} from '../hooks/auth';
import {ResetPasswordWithCode} from '../components/Forgot/ResetPassword';

export const Forgot = () => {
  const forgotData = useForgotPassword();

  return <ForgotPassword forgotData={forgotData} />;
};

export const ForgotReset = () => {
  const resetData = useResetWithCode();

  return <ResetPasswordWithCode resetData={resetData} />;
};
