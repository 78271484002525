import React from 'react';
import {AppLayout} from '../ui-kit/AppLayout';
import {WithChildren} from '../types/helpers';
import styled from 'styled-components';
import {Header} from '../ui-kit/AppLayout/Header';
import {Media, isMobile} from '../ui-kit/theme/breakpoints';

const StyledAppLayout = styled(AppLayout)`
  background-color: ${({theme}) => theme.palette.background.gray};
  & main {
    margin-bottom: 0;
  }
  ${Media.down.m} {
    min-height: auto;
  }
`;

export const Layout: React.FC<WithChildren> = ({children}) => {
  return (
    <>
      {isMobile && <Header />}
      <StyledAppLayout header={null} footer={null}>
        {children}
      </StyledAppLayout>
    </>
  );
};
