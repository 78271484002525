import React from 'react';
import {
  Input as StyledInput,
  InputNumber as StyledInputNumber,
  TextArea as StyledTextArea,
  SearchInput as StyledSearchInput,
  FormItem as StyledFormItem,
} from './styles';
import {InputProps} from 'antd/lib/input/Input';
import {FormItemProps, InputNumberProps} from 'antd';
import {SearchProps, TextAreaProps} from 'antd/es/input';

const Input: React.FC<InputProps> = ({...props}) => <StyledInput {...props} />;

export const InputNumber: React.FC<InputNumberProps> = ({...props}) => <StyledInputNumber {...props} />;

export const TextArea: React.FC<TextAreaProps> = ({...props}) => <StyledTextArea {...props} />;

export const SearchInput: React.FC<InputProps & SearchProps> = ({...props}) => <StyledSearchInput {...props} />;

export const FormItem: React.FC<FormItemProps> = ({...props}) => <StyledFormItem {...props} />;

export default Input;
