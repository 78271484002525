import React from 'react';
import logo from './assets/ENC_Logo_2017_FINAL_BLK.png';
import {LogoSectionTitle} from './styles';

export const LogoSection: React.FC = () => {
  return (
    <>
      <img src={logo} />
      <LogoSectionTitle>A world of wonder awaits</LogoSectionTitle>
    </>
  );
};
