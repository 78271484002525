import {isMobile} from './../ui-kit/theme/breakpoints';
import {
  authorizeQuery,
  loginQuery,
  RequestPasswordWithCode,
  ResetPassword,
  SendResetPasswordEmail,
  UpdatePassword,
} from '../queries/auth';
import {CODES_PORTAL_URL, GROUPS_PORTAL_URL, NOTIFICATIONS_PORTAL_URL} from '../constants/env';
import Cookies from 'js-cookie';
import {Portals, searchKeys} from '../types/helpers';
import {useState} from 'react';
import {PortalsList, User} from '../types/auth';
import {toResetByEmailData, toResetPasswordData, toResetWithCodeData, toUpdatePasswordData} from './helpers';
import {useNavigate, useSearchParams} from 'react-router-dom';

export enum LoginFormFields {
  email = 'email',
  password = 'password',
}

type LoginFormValues = {
  email?: string;
  password?: string;
};

export type useLoginRes = {
  error?: string;
  onSubmit: (values: LoginFormValues) => void;
  resetError?: () => void;
  loading?: boolean;
};

type authRes = {
  expires_in: number;
  id_token: string;
  token_type: string;
};

type TokenType = string | null | undefined;

export const getToken = (): TokenType => {
  return Cookies.get('id_token');
};

export const useLogin = (onSuccess?: () => void): useLoginRes => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const resetError = () => setError('');
  const onSubmit = async (values: LoginFormValues) => {
    try {
      if (values.email && values.password) {
        setLoading(true);
        const res = await loginQuery({email: values.email, password: values.password});
        if (res) {
          setError('');
          const resBody = res?.body as authRes;
          const token = resBody.id_token;
          if (token) {
            Cookies.set('id_token', token, {expires: resBody.expires_in});

            onSuccess?.();
            // const lsPortal = window?.localStorage.getItem('from');
            // const redirectPortal = chosePortal(lsPortal as Portals);
            // window?.localStorage.removeItem('from');
            // redirectPortal && window.location.replace(urlWithToken(redirectPortal, token, resBody.expires_in));
          } else Cookies.remove('id_token');
        }
        if (!res) setError('Invalid password. Please enter valid password.');
      }
    } catch (e) {
      setError('Invalid password. Please enter valid password.');
    } finally {
      setLoading(false);
    }
    return false;
  };

  return {onSubmit, error, resetError, loading};
};

export const useCheckAuthorize = () => async () => {
  const res = await authorizeQuery();
  return res?.body as User;
};

export const urlWithToken = (url: string, token: string, expires?: number) =>
  `${url}?token=${token}&expires=${expires || 604800}`;

export const useRedirectToPortal = () => {
  const checkAuth = useCheckAuthorize();

  const check = () => {
    const token = getToken();
    if (!token) return;
    checkAuth()
      .then((res) => {
        if (!res?.portals?.length) return;
        localStorage.removeItem('email');
        const redirectPortal = chosePortalAccess(res.portals?.[0] as PortalsList);
        redirectPortal && window.location.replace(urlWithToken(redirectPortal, token));
      })
      .catch((e) => console.log(e));
  };
  return check;
};

export const useLogOut = () => {
  return () => {
    Cookies.remove('id_token');
  };
};

export const chosePortal = (from: Portals | null) => {
  if (from === Portals.codes) return CODES_PORTAL_URL;
  if (from === Portals.groups) return GROUPS_PORTAL_URL;
  if (from === Portals.notifications) return NOTIFICATIONS_PORTAL_URL;

  return CODES_PORTAL_URL;
};
export const chosePortalAccess = (from: PortalsList | null) => {
  if (from === PortalsList.promo) return CODES_PORTAL_URL;
  if (from === PortalsList.ticket) return GROUPS_PORTAL_URL;
  if (from === PortalsList.notificiations) return NOTIFICATIONS_PORTAL_URL;

  return CODES_PORTAL_URL;
};

export const setLogoutPortal = (from: Portals) => {
  from && window?.localStorage.setItem('from', from);
};

export const useCheckLogged = () => {
  const checkAuth = useCheckAuthorize();

  const portals = {
    [Portals.codes]: PortalsList.promo.toLowerCase(),
    [Portals.groups]: PortalsList.ticket.toLowerCase(),
    [Portals.notifications]: PortalsList.notificiations.toLowerCase(),
  };
  const checkAndRedirect = async () => {
    const token = getToken();
    const lsPortal = window?.localStorage.getItem('from');
    window?.localStorage.removeItem('from');

    const res = await checkAuth();
    if (!res?.portals?.length) return;

    const hasPortalFromLS = lsPortal
      ? res?.portals?.find((el) => el?.toLowerCase() === portals?.[lsPortal as Portals])
      : false;
    const redirectPortal = hasPortalFromLS
      ? chosePortal(lsPortal as Portals)
      : chosePortalAccess(
          isMobile && res.portals?.[2] ? (res.portals?.[2] as PortalsList) : (res.portals?.[0] as PortalsList),
        );

    if (!token || !redirectPortal) return;
    redirectPortal && window.location.replace(urlWithToken(redirectPortal, token));
  };

  return {checkAndRedirect};
};

export const useSelectPortal = (userData?: User) => {
  const [portal, setPortal] = useState<string | undefined>();
  const onSetPortal = (val?: string) => setPortal(val);
  const portals = [
    PortalsList.promo.toLowerCase(),
    PortalsList.ticket.toLowerCase(),
    PortalsList.notificiations.toLowerCase(),
  ];
  const options: {label?: string; value?: string}[] =
    userData?.portals?.filter((el) => portals.includes(el.toLowerCase())).map((el) => ({label: el, value: el})) || [];

  const goToPortal = () => {
    const token = getToken();
    const redirectPortal = chosePortalAccess(portal as PortalsList);
    if (!token || !redirectPortal) return;
    redirectPortal && window.location.replace(urlWithToken(redirectPortal, token));
  };

  return {options, portal, setPortal: onSetPortal, goToPortal};
};

export type useForgotPasswordT = {
  statusViaEmail: number;
  resetStatues: () => void;
  resetByEmail: (val: any) => Promise<boolean | undefined>;
  updatePassword: (val: any) => Promise<boolean | undefined>;
  resetPassword: (val: any) => Promise<boolean | undefined>;
  isEmailReset: boolean;
  isCodeReset: boolean;
};

export const useForgotPassword = (): useForgotPasswordT => {
  const [statusViaEmail, setStatusViaEmail] = useState(0);
  const isEmailReset = true;
  const isCodeReset = false;

  const resetStatues = () => {
    setStatusViaEmail(0);
  };

  const resetByEmail = async (values: any) => {
    try {
      const body = toResetByEmailData(values);
      if (!body) return false;

      const res = await SendResetPasswordEmail(body);
      setStatusViaEmail(1);
      localStorage.setItem('email', body.email);
      console.log(res);
    } catch (e) {
      setStatusViaEmail(-1);
    }
  };
  const updatePassword = async (values: any) => {
    try {
      const body = toUpdatePasswordData(values);
      if (!body) return false;

      const res = await UpdatePassword(body);
      console.log(res);
    } catch (e) {}
  };
  const resetPassword = async (values: any) => {
    try {
      const body = toResetPasswordData(values);
      if (!body) return false;

      const res = await ResetPassword(body);
      console.log(res);
    } catch (e) {}
  };

  return {
    statusViaEmail,
    resetByEmail,
    updatePassword,
    resetPassword,
    isEmailReset,
    isCodeReset,
    resetStatues,
  };
};

export type useResetWithCode = {
  resetWithCode: (val: any) => Promise<boolean | undefined>;
  error: string;
};
export const useResetWithCode = (): useResetWithCode => {
  const [error, setError] = useState('');
  const [searchParams] = useSearchParams();
  const code = searchParams.get(searchKeys.code) || searchParams.get('magicCode');
  const email = localStorage.getItem('email');
  const navigate = useNavigate();

  const resetWithCode = async (values: any) => {
    try {
      const body = toResetWithCodeData({...values, email, code});
      if (!body) return false;

      const res = await RequestPasswordWithCode(body);
      if (res) navigate('/?updatedPass=true');
    } catch (e) {
      console.log(e);
      const error = JSON.parse(JSON.stringify(e));
      const errorMessage = error?.response?.text && JSON.parse(error?.response?.text)?.message;
      setError(errorMessage);
      return false;
    }
  };
  return {resetWithCode, error};
};
