import {css} from 'styled-components';
import {mixins} from './mixins';

export const font = {
  weight: {
    regular: 400,
    medium: 500,
    mediumBold: 600,
    bold: 700,
  },
};

//300
export const fontPrimaryLight = css`
  font-family: ${mixins.font.primary.light.fontFamily};
  font-weight: ${mixins.font.primary.light.fontWeight};
`;

//700
export const fontPrimaryBold = css`
  font-family: ${mixins.font.primary.bold.fontFamily};
  font-weight: ${mixins.font.primary.bold.fontWeight};
`;

//300
export const fontSecondaryLight = css`
  font-family: ${mixins.font.secondary.light.fontFamily};
  font-weight: ${mixins.font.secondary.light.fontWeight};
`;

//700
export const fontSecondaryBold = css`
  font-family: ${mixins.font.secondary.bold.fontFamily};
  font-weight: ${mixins.font.secondary.bold.fontWeight};
`;
