export type WithChildren = {
  children?: React.ReactNode;
};

export enum searchKeys {
  logout = 'logout',
  from = 'from',
  code = 'code',
  updatedPass = 'updatedPass',
}

export enum Portals {
  codes = 'codes',
  groups = 'groups',
  notifications = 'notifications',
}
