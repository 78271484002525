export const QueryList = {
  signUp: '/v1/auth/signup',
  login: '/v1/auth/login',
  authorize: '/v1/auth/authorize',
  //reset pass
  updatePassword: `/v1/auth/resetPassword`,
  requestPasswordReset: `/v1/auth/sendResetPasswordEmail`,
  resetPasswordUsingCode: `/v1/auth/resetPasswordUsingCode`,
  resetPassword: (userId: string) => `/v1/user/${userId}/resetPassword`,
};
