export type RequestPasswordWithCodeReq = {
  email: string;
  code: string;
  newPassword: string;
};

export type SendResetPasswordEmailReq = {
  email: string;
  portal: string;
  userType: string;
};

export type UpdatePasswordReq = {
  email: string;
  existingPassword: string;
  newPassword: string;
};

export type ResetPasswordReq = {
  userId: string;
  body: {
    password: string;
  };
};

export enum ForgotPassFields {
  email = 'email',
  existingPassword = 'existingPassword',
  newPassword = 'newPassword',
  password = 'password',
  code = 'code',
}
