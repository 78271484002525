import React, {useEffect, useState} from 'react';
import {LogIn} from '../components/Auth/LogIn';
import {setLogoutPortal, useCheckLogged, useLogin, useLogOut, useRedirectToPortal} from '../hooks/auth';
import {useSearchParams} from 'react-router-dom';
import {Portals, searchKeys} from '../types/helpers';

const AuthPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [successUp, setSuccessUp] = useState(false);
  const isLogOut = searchParams.get(searchKeys.logout) === 'true';
  const {checkAndRedirect} = useCheckLogged();
  const checkRedirect = useRedirectToPortal();
  const handleLogout = useLogOut();
  const isPassUpdated = searchParams.get(searchKeys.updatedPass) as Portals;
  const authData = useLogin(checkAndRedirect);
  const from = searchParams.get(searchKeys.from) as Portals;

  useEffect(() => {
    setSuccessUp(!!isPassUpdated);
    if (from) setLogoutPortal(from);
    if (!isLogOut) {
      checkRedirect();
      searchParams.delete(searchKeys.logout);
      searchParams.delete(searchKeys.from);
      setSearchParams(searchParams);
      return;
    }
    handleLogout();
    searchParams.delete(searchKeys.logout);
    searchParams.delete(searchKeys.from);
    searchParams.delete(searchKeys.updatedPass);
    setSearchParams(searchParams);
  }, [isLogOut]);

  return <LogIn authData={authData} successUpdated={successUp} />;
};

export default AuthPage;
