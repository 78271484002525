import React from 'react';
import Auth from './containers/Auth';
import {defaultTheme} from './ui-kit/theme/theme';
import {Normalize} from 'styled-normalize';
import {ThemeProvider} from 'styled-components';
import {Route, Routes} from 'react-router-dom';
import 'antd/dist/antd.css';
import './ui-kit/fonts/fonts.css';
import {Layout} from './containers/Layout';
import {Forgot, ForgotReset} from './containers/Forgot';

function App() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <Layout>
        <Routes>
          <Route key="forgot-reset" path={'/reset-password'} element={<ForgotReset />} />
          <Route key="forgot" path={'/forgot-password'} element={<Forgot />} />
          <Route key="login" path={'*'} element={<Auth />} />
        </Routes>
      </Layout>
      <Normalize />
    </ThemeProvider>
  );
}

export default App;
