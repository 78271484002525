import {
  RequestPasswordWithCodeReq,
  ResetPasswordReq,
  SendResetPasswordEmailReq,
  UpdatePasswordReq,
} from '../queries/types';

export const toResetByEmailData = (values: any): SendResetPasswordEmailReq | null => {
  if (!values?.email) return null;
  return {
    email: values.email,
    portal: 'admin',
    userType: 'internal',
  };
};

export const toResetWithCodeData = (values: any): RequestPasswordWithCodeReq | null => {
  if (!values?.email || !values?.code || !values?.newPassword) return null;
  return {
    email: values.email,
    code: values.code,
    newPassword: values.newPassword,
  };
};

export const toUpdatePasswordData = (values: any): UpdatePasswordReq | null => {
  if (!values?.email || !values?.existingPassword || !values?.newPassword) return null;
  return {
    email: values.email,
    existingPassword: values.code,
    newPassword: values.newPassword,
  };
};

export const toResetPasswordData = (userId?: string, values?: any): ResetPasswordReq | null => {
  if (!userId || !values?.password) return null;
  return {
    userId,
    body: {
      password: values.password,
    },
  };
};
