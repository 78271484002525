export interface User {
  active?: boolean;
  createdAt?: Date;
  email?: string;
  firstName?: string;
  id?: number;
  lastName?: string;
  phoneNumber?: string | null;
  timezone?: string | null;
  updatedAt?: Date;
  userType?: string;
  departments?: UserDepartmentsT[];
  roles?: UserRolesT[];
  portals?: string[];
}

export type UserDepartmentsT = string;

export type UserRolesT = string;

export enum PortalsList {
  promo = 'Promo Code',
  ticket = 'Group Ticket',
  notificiations = 'Notification',
}
